import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderExportNavModule } from './extensions/bf524671438aa2d29e6cf34495e5a2f13a3d6cc932bd7e915fb651a05703a3aa/order-export-nav.module';
import { BadgesNavModule } from './extensions/d7bbadeadd4a61425667f0d9c83bb6fd64e7a9ac1f5b7336cdf5926e510b0aa3/badge-nav.module';

import SharedProviders_2_0 from './extensions/product-importer-ui/providers';


@NgModule({
    imports: [CommonModule, OrderExportNavModule, BadgesNavModule],
    providers: [...SharedProviders_2_0],
})
export class SharedExtensionsModule {}
