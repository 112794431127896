export const extensionRoutes = [  {
    path: 'extensions/export-orders',
    loadChildren: () => import('./extensions/bf524671438aa2d29e6cf34495e5a2f13a3d6cc932bd7e915fb651a05703a3aa/order-export.module').then(m => m.OrderExportModule),
  },
  {
    path: 'extensions/badges',
    loadChildren: () => import('./extensions/d7bbadeadd4a61425667f0d9c83bb6fd64e7a9ac1f5b7336cdf5926e510b0aa3/badge.module').then(m => m.BadgeModule),
  },
  {
    path: 'extensions/product-importer',
    loadChildren: () => import('./extensions/product-importer-ui/routes'),
  }];
